import React, { Component } from 'react';

import './styles.css'
import { fetchEnter } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Landing extends Component{
    constructor(props){
      super(props);

      this.state = {
        activeRequest: false,
        responseCode: 0,
        fileCode: null
      }

      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
      e.preventDefault();
      const code = e.target.value;

      const lastInput = code.substring(code.length -1, code.length);
      if(lastInput >= '0' && lastInput <= '9')
      {
        if(code.length === 7)
          this.sendEnter(code);
        else if(code.length === 8)
          e.target.value = code.substring(0, code.length - 1);
      }
      else{
        e.target.value = code.substring(0, code.length - 1);
      }
    }

    sendEnter(code){
      if(!this.state.activeRequest){
        this.props.fetchEnter(code);
        this.setState({activeRequest:true});
      }
    }

    componentDidUpdate(nextProps) {
      const { app } = this.props;

      if (nextProps.app !== app) {
          if (app) {
              this.setState({ 
                  fileCode: app.data,
                  activeRequest: false
              });
          }
      }
  }

  renderResponseCode() {
    if(this.props.app && this.props.app.data)
      console.log(this.props.app.data)
    return (
      <div>
        {
          this.state.activeRequest && (!this.props.app || this.props.app.code != 0) && <div>Loading ...</div>
        }
        {
           this.props.app && this.props.app.data == null && <div>Invalid code</div>
        }
        {
           this.props.app && this.props.app.data != null && <Link className='button enter-button' to={'/file/' + this.props.app.data}>Enter</Link>
        }
      </div>
    )
  }

  render() {
      return (
        <div className='container'>
          <div className='hero-container'>
            <img className='hero' src='./hero.png' />
          </div>
          <div className='prompt-container'>
            <div className='prompt'>
              <h1>Mercenary Camp</h1>
              <h5>Coders for hire</h5>
              <div>
                <input maxLength='7' 
                  readOnly={this.state.activeRequest}
                  onInput={this.handleChange} className='prompt-container-input' autoComplete="off" 
                  onPaste={(e)=>{e.preventDefault(); return false;}} 
                  onCopy={(e)=>{e.preventDefault(); return false;}}
                  placeholder="*******" />
              </div>
            </div>
            <div className='loading-container'>
                { this.renderResponseCode() }
            </div>
          </div>
        </div>
      );
  }
};

function mapStateToProps({ app }) {
  return { app };
}
export default connect(mapStateToProps, { fetchEnter })(Landing);
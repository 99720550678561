import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import Landing from '../Landing';
import File from '../File';

class App extends Component {
  componentDidMount() {

  }

  render() {
    return (
      <div className="container">
        <BrowserRouter>
            <Routes>
                <Route path="/file/*" element={<File/>} />
                <Route path="/*" element={<Landing/>} />
            </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(null, actions)(App);
import React, { Component } from 'react';

import './styles.css'
import { fetchEnter } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class File extends Component{
    constructor(props){
      super(props);

      this.state = {
        fileCode: null
      }
    }

    componentDidUpdate(nextProps) {
      const { app } = this.props;

      if (nextProps.app !== app) {
          if (app) {
              this.setState({ 
                  alertMessage: app.fileCode,
                  activeRequest: false
              });
          }
      }
  }

  render() {
      return (
        <div className='container'>
          <div className='hero-container'>
            <img className='hero' src='./hero.png' />
          </div>
          <div className='prompt-container'>
              <div>Hello</div>
          </div>
        </div>
      );
  }
};

function mapStateToProps({ app }) {
  return { app };
}
export default connect(mapStateToProps, { })(File);